import React from "react";
import { UpcManager } from "./UpcManager";
import Header from "../Header/Header";

export const UpcManagerWrapper = () => {
    return (
        <div className="min-width-scroll">
            <Header />
            <UpcManager />
        </div>
    )
}