import React, { useRef } from "react";
import { Button } from "primereact/button";
import { useAuth } from "../../context/AuthProvider";
import { Toast } from "primereact/toast";
import { sendExportCSV, sendExport } from "./ExportButtonFuncs";
type ExportButtonProps = {
  products: object[],
  fileName: string,
  wordLists?: object,
  URL?: string,
  URLSuffix?: string,
  isCSV?: boolean,
  className?: string
};

/**
 * Export button component for exporting data.
 * 
 * @param {Object} props - The component props.
 * @param {Array} props.products - The list of products to export.
 * @param {string} props.fileName - The name of the exported file.
 * @param {Object} [props.wordLists={}] - Optional word lists for filtering.
 * @param {string} [props.URL=''] - The export URL (defaults to the current running server).
 * @param {boolean} [props.isCSV=false] - Whether to export as CSV.
 * @param {string} [props.className=''] - Additional CSS class names.
 */
export const ExportButton = ({ products, fileName, wordLists = {}, URL = '', URLSuffix='generator', isCSV = false, className = '' }: ExportButtonProps) => {
  const { session } = useAuth();
  const toastRef = useRef(null);

  return (
    <>
      <Toast ref={toastRef} />
      {products.length > 0 && (
        <Button
          className={"p-button-warning " + className}
          data-pr-tooltip="Excel"
          icon="pi pi-file-excel"
          label={`Export ${isCSV ? 'CSV' : 'XLSX'}`}
          onClick={async () => isCSV ? sendExportCSV(products, fileName, toastRef) : sendExport(products, wordLists, session, fileName, toastRef, URL, URLSuffix)}
          data-testid="Export-Button"
          size='small'
        />
      )}
    </>
  );
} 