import { useCallback, useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { supabaseClient } from '../../config/supabase-client';
import './UpcManager.css'
import { FilterMatchMode } from "primereact/api";
import { MultiSelect } from "primereact/multiselect";
import { SelectItemOptionsType } from "primereact/selectitem";
import { Tag } from "primereact/tag";
import { ExportButton } from "../ExportButton/ExportButton";
import { Message } from "primereact/message";
const { REACT_APP_THINKHAUS_URL
} = process.env

export const UpcManager = () => {
  const [products, setProducts] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const defaultDateFilter = {
    startDate: new Date(new Date().setDate(new Date().getDate() - 7)), // 7 days ago
    endDate: new Date(), // Today
  }
  const [dateFilter, setDateFilter] = useState<{ startDate: Date | null; endDate: Date | null }>(defaultDateFilter);
  const [tableFilters, setTableFilters] = useState({
    upc: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    title: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    update_user_email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    request_type: { value: null, matchMode: FilterMatchMode.IN }
  });

  const calendarRef = useRef(null);

  const fetchResults = useCallback(async () => {
    let query = supabaseClient
      .from('syndigo_request_combined_view ')
      .select("*");

    const start = dateFilter?.startDate.toISOString();
    const end = dateFilter?.endDate ? dateFilter?.endDate.toISOString() : start;

    if (start && end) {
      query = query
        .filter("date", "gte", start)
        .filter("date", "lte", end)
        .filter('is_success', 'eq', true)
    } 

    const { data, error } = await query;

    if (error) {
      console.error('Error fetching data:', error);
    } else {
      const productsResponse = data.map(product => {
        return {
          upc: product.upc,
          title: product.product_title,
          last_edit_time: product.date,
          is_success: product.is_success,
          update_user_email: product.email,
          request_type: product.request_type
        }
      })
      setProducts(productsResponse)
      !filteredData.length && setFilteredData(productsResponse)
    }
  }, [products, dateFilter]);

  useEffect(() => {
    fetchResults();
  }, [dateFilter]);

  const onDateFilterChange = (e: any) => {
    if (e.value) {
      setDateFilter({ startDate: e.value[0], endDate: e.value[1] });
    }
  };

  const handleClearButtonClick = () => {
    setDateFilter(defaultDateFilter);
  };


  const dateFilterTemplate = () => {
    return (
      <Calendar
        ref={calendarRef}
        value={[dateFilter.startDate, dateFilter.endDate]}
        onChange={onDateFilterChange}
        onClearButtonClick={handleClearButtonClick}
        selectionMode="range"
        placeholder="Select date range"
        dateFormat="yy-mm-dd"
        showButtonBar
        className="p-column-filter"
      />
    );
  };

  const typeFilters: SelectItemOptionsType = [
    { label: 'Publish', value: 'publish' },
    { label: 'Image Upload', value: 'upload' },
    { label: 'Syndigo Update', value: 'update' },
  ]

  const getSeverity = (request_type) => {
    if (request_type === 'publish') {
      return 'info'
    }
    if (request_type === 'update') {
      return 'warning'
    }
    else {
      return 'secondary'
    }
  }

  const RequestTypeBodyTemplate = (rowData) => {
    return <Tag value={typeFilters.find((op) => op.value === rowData.request_type)?.label} severity={getSeverity(rowData.request_type)} />;
  };

  const TypeFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={typeFilters}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Any"
        className="p-column-filter"
      />
    )
  };

  const DateTemplate = (rowData) => {
    const date = new Date(rowData.last_edit_time)
    const formatter = new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric', timeZone: 'UTC' })
    return formatter.format(date).replace(' ', '-')
  }

  return (
    <>
    {products.length === 1000 && <Message className="w-full mt-3" severity="warn" text="Too many rows returned. Please select a smaller date range to see accurate data." />}
    <DataTable
      value={products}
      filterDisplay="row"
      filters={tableFilters}
      onFilter={(e: any) => setTableFilters(e.filters)}
      stripedRows
      className="my-3 border-1 border-500"
      header={
        <ExportButton
          fileName={`${dateFilter.startDate.toISOString().split('T')[0]}_${dateFilter.endDate?.toISOString().split('T')[0]}-request_manager`}
          products={filteredData}
          URL={REACT_APP_THINKHAUS_URL}
          URLSuffix="upc-manager"
        />
      }
      onValueChange={(value: any) => setFilteredData(value)}
    >
      <Column
        field="upc"
        header={<span data-cy-upcheader>UPC</span>}
        filter
      />
      <Column
        field="title"
        header={<span data-cy-fieldheader>Title</span>}
        filter
      />
      <Column
        field="last_edit_time"
        header={<span data-cy-statusheader>Last Edit Time</span>}
        filter
        filterElement={dateFilterTemplate}
        body={DateTemplate}
      />
      <Column
        field="update_user_email"
        header={<span data-cy-upcheader>Email</span>}
        filter
      />
      <Column
        field="request_type"
        header={<span data-cy-upcheader>Request Type</span>}
        filter
        filterField="request_type"
        filterElement={TypeFilterTemplate}
        body={RequestTypeBodyTemplate}
      />
    </DataTable>
    </>
  );
}