import { saveAs } from 'file-saver';
import axios from "axios";
import { Session } from '@supabase/supabase-js';

export const sendExport = async (productsArray, wordLists, session: Session, fileName: string, toastRef: React.MutableRefObject<any>, URL: string, URLSuffix: string) => {
    try {
        const response = await axios.post(URL + "api/download/excel/" + URLSuffix, { productsArray, wordLists }, {
            responseType: 'blob',
            headers: {
                "Authorization": `Bearer ${session.access_token}`,
            }
        });
        const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const formatFileName = fileName + '_export_' + new Date().getTime() + '.xlsx';
        saveAs(blob, formatFileName);
    } catch (error) {
        console.error("Error downloading the file", error);

        let errorMessage = 'An unexpected error occurred';

        if (axios.isAxiosError(error) && error.response) {
            if (error.response.data instanceof Blob) {
                // Read the Blob data as text synchronously
                const blobText = await new Promise<string>((resolve) => {
                    const reader = new FileReader();
                    reader.onload = () => resolve(reader.result as string);
                    reader.readAsText(error.response.data);
                });
                errorMessage = blobText || 'Error downloading XLSX file';
            } else {
                errorMessage = error.response.data.message || error.message;
            }
        } else if (error instanceof Error) {
            errorMessage = error.message;
        }

        toastRef.current.show({
            severity: 'error',
            summary: 'Error downloading XLSX file',
            detail: errorMessage || 'Unexpected Error',
            sticky: true
        })
    }
};

export const sendExportCSV = async (productsArray, fileName: string, toastRef: React.MutableRefObject<any>) => {
    try {
        // Convert products array to CSV string
        const headers = Object.keys(productsArray[0]).join(',');
        const csvRows = productsArray.map(product =>
            Object.values(product).map(value =>
                typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value
            ).join(',')
        );
        const csvString = [headers, ...csvRows].join('\n');

        // Create and save the CSV file
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const formatFileName = fileName + '_export_' + new Date().getTime() + '.csv';
        saveAs(blob, formatFileName);
    } catch (error) {
        console.error("Error downloading the file", error);

        toastRef.current.show({
            severity: 'error',
            summary: 'Error downloading CSV file',
            detail: error.message
        })
    }
}